import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { HasAuthorityModule } from '../../auth/has-authority.module';
import { CsvDownloaderComponent } from './csv-downloader/csv-downloader.component';
import { CheckLinkRenderComponent, RouteLinkRenderComponent } from './custom-render';
import { CustomTableComponent } from './custom-table.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    Angular2SmartTableModule,
    RouterModule,
    HasAuthorityModule,
    FormsModule,
  ],
  declarations: [ CustomTableComponent, CsvDownloaderComponent, RouteLinkRenderComponent, CheckLinkRenderComponent ],
  exports: [ CustomTableComponent ]
})
export class CustomTableModule { }

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhihasAuthority="'ADMIN'">...</some-element>
 *
 *     <some-element *jhihasAuthority="['ADMIN', 'USER']">...</some-element>
 * ```
 */
@Directive({
  selector: '[hasAuthority]'
})
export class HasAuthorityDirective {

  private authorities: string[] = [];

  constructor(private oidcSecurityService: OidcSecurityService, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set hasAuthority(value: string[]) {
    this.authorities = value;
    // this.updateView();
    // Get notified each time authentication state changes.
    this.oidcSecurityService.getUserData().subscribe((userData) => {
      this.updateView(userData);
    });
  }

  private updateView(userData: any): void {
    this.viewContainerRef.clear();
    let atLeastOneAuthorities = false;
    this.authorities.forEach((auth: string) => {
      atLeastOneAuthorities = atLeastOneAuthorities || userData?.roles?.includes(auth);
    });
    if (atLeastOneAuthorities) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { HasAuthorityModule } from './auth/has-authority.module';
import { CustomTableModule } from './component/custom-table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CustomTableModule,
    RouterModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    ButtonsModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    HasAuthorityModule,
    AngularMyDatePickerModule,
    BsDropdownModule,
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CustomTableModule,
    TabsModule,
    AccordionModule,
    ModalModule,
    ButtonsModule,
    TypeaheadModule,
    AngularMyDatePickerModule,
    HasAuthorityModule,
    BsDropdownModule,
  ]
})
export class SharedModule {
}

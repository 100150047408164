import { NgModule } from '@angular/core';
import { HasAuthorityDirective } from './has-authority.directive';

@NgModule({
  imports: [
  ],
  declarations: [ HasAuthorityDirective ],
  exports: [ HasAuthorityDirective ]
})
export class HasAuthorityModule { }
